import React from 'react';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Breadcrumbs,
  Grid,
  makeStyles,
  Typography,
  Button,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import blueGrey from '@material-ui/core/colors/blue';
import { selectAuth, selectProfile, UserProfile } from 'app';
import { createProfile } from 'app/profileSlice';
import { Link } from 'components';
import { QuestionStepper } from 'components/questions';
import { AppPage } from 'components/layout';
import { UserCategory, getQuestions } from './profileQuestions';
import create_profile from './create_profile.png';
import { isFacebookUser } from 'app/helpers';

const useStyles = makeStyles((theme) =>
  createStyles({
    hero: {
      backgroundColor: blueGrey[50],
      marginTop: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    image: {
      width: '80%',
      height: '80%',
      maxWidth: '300px',
      maxHeight: '300px',
    },
  }),
);

export type CreateProfileContentProps = {};

export function CreateProfileContent(props: CreateProfileContentProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, fetched, status } = useSelector(selectProfile);
  const { userInfo } = useSelector(selectAuth);
  const completed = fetched && data !== null;
  const [step, setStep] = React.useState(-1);
  const [userProfile, setUserProfile] = React.useState<Partial<UserProfile>>({});
  const [warning, setWarning] = React.useState<boolean>(false);
  const [warningMsg, setWarningMsg] = React.useState<string>('');

  const facebookUser = Boolean(userInfo?.providerData && isFacebookUser(userInfo.providerData));
  const userCategory = () => {
    const { userCategory } = userProfile;
    if (userCategory && userCategory !== 'parent') {
      return userCategory === 'professional' ? UserCategory.Professional : UserCategory.Other;
    }
  };

  const profileQuestions = getQuestions({ facebookUser, userCategory: userCategory() });

  React.useEffect(() => {
    setUserProfile({
      smsNotification: true,
      userContactEmail: userInfo && userInfo.email ? userInfo.email : '',
    });
  }, [userInfo]);

  React.useEffect(() => {
    if (status?.error && !warning) {
      setWarningMsg(status.error);
      setWarning(true);
      setStep(4);
    }
  }, [status]);

  React.useEffect(() => {
    if (completed) {
      setStep(profileQuestions.length);
    } else if (step === profileQuestions.length) {
      dispatch(createProfile(userProfile));
    }
  }, [completed, step]);

  return (
    <AppPage title="Create Profile">
      <Box pt={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>
          <Typography color="textPrimary">Settings</Typography>
        </Breadcrumbs>

        <Grid container alignItems="center" className={classes.hero}>
          <Grid item sm={12} md={6}>
            <Box p={4} display="flex" justifyContent="center">
              <img src={create_profile} className={classes.image} />
            </Box>
          </Grid>

          <Grid item sm={12} md={6}>
            <Box p={4}>
              <Typography variant="h4" gutterBottom>
                Welcome
              </Typography>

              <Typography variant="body1" color="textSecondary" paragraph>
                Welcome and congratulations on taking this step to protect your teenager’s mental wellbeing. First,
                please take some time to read the information below and complete your profile.{' '}
                <strong>You'll also need to verify your email.</strong> If you signed up with an email address, you
                should have received a verification email. Please take a moment to click the link in that email. If you
                signed up with Facebook, you will receive the verification email after you complete your profile.
              </Typography>

              <div className={classes.actionsContainer}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={step >= 0}
                  onClick={() => setStep(0)}
                  className={classes.button}>
                  Start
                </Button>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <QuestionStepper
              sections={profileQuestions}
              step={step}
              setStep={setStep}
              data={userProfile}
              onChange={setUserProfile}
              finishLabel="I Agree"
            />
          </Grid>
        </Grid>

        <Dialog open={completed}>
          <DialogTitle>Thank you</DialogTitle>
          <DialogContent>
            <Typography variant="body1" paragraph>
              You have now completed your profile. Check out your personal dashboard for the next steps of your program.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => navigate('/dashboard', { replace: true })}>Go</Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Snackbar open={warning} autoHideDuration={6000} onClose={() => setWarning(false)}>
        <Alert onClose={() => setWarning(false)} severity="error">
          {warningMsg}
        </Alert>
      </Snackbar>
    </AppPage>
  );
}
