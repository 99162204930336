import React from 'react';
import { Box, styled, Typography } from '@material-ui/core';
import blueGrey from '@material-ui/core/colors/blueGrey';
import { ExtLink } from 'components';
import { mustBeEmail, mustBeAuNumber, mustBeAge, mustBePostcode, mustBeDateOfBirth } from 'components/form';
import { QuestionItem, QuestionSection } from 'components/questions';
import update from 'immutability-helper';

const Article = styled('article')(({ theme }) => ({
  '& li': {
    ...theme.typography.body1,
  },
}));

export enum UserCategory {
  Parent = 0,
  Professional = 1,
  Other,
}

export const getQuestions = (opts: { facebookUser: boolean; userCategory?: UserCategory }): QuestionSection[] => {
  const { facebookUser, userCategory } = opts;
  let questions: QuestionSection[] = [
    {
      key: 'welcome',
      title: 'Partners in Parenting',
      questions: [
        {
          key: 'intro',
          type: 'paragraph',
          content: (
            <Article>
              <Typography variant="subtitle1" gutterBottom>
                <i>Partners in Parenting</i> is a free online program that aims to prevent depression and anxiety among
                teenagers.
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                Parents have an important positive influence on their teenager’s mental wellbeing. There’s now good
                evidence to show that by supporting parents in their parenting we can help teenagers to stay well.
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                By participating in this program you’ll learn some new parenting strategies, and by completing some
                optional evaluation surveys, you’ll be helping us to improve the impact of the program.
              </Typography>
            </Article>
          ),
        },
      ],
    },
    {
      key: 'features',
      title: 'Program features',
      questions: [
        {
          key: 'intro',
          type: 'paragraph',
          content: (
            <Article>
              <Box p={2} bgcolor={blueGrey[50]} borderRadius={8}>
                <ul>
                  <li>Take our parenting survey to receive personalised feedback about your parenting</li>
                  <li>Receive feedback about your own and your teen’s mental wellbeing</li>
                  <li>Access up to 10 interactive online modules (15-25 mins each) tailored for you</li>
                  <li>New, expert-endorsed information about parenting through the COVID-19 pandemic</li>
                  <li>Access to an online peer support community, to connect with other PiP parents</li>
                  <li>Be part of a world leading research project.</li>
                </ul>
              </Box>
            </Article>
          ),
        },
      ],
    },
    {
      key: 'who',
      title: 'Who can sign up?',
      questions: [
        {
          key: 'intro',
          type: 'paragraph',
          content: (
            <Article>
              <Typography variant="subtitle1" gutterBottom>
                Parents or guardians of a teenager aged 12 to 17 who:
              </Typography>
              <ul>
                <li> Live in Australia</li>
                <li> Are fluent in English</li>
                <li> Have internet access.</li>
              </ul>

              <Typography variant="subtitle1" gutterBottom>
                To find out more about what participating in this program involves, please read the{' '}
                <ExtLink href="/settings/explanatoryStatement">Explanatory Statement</ExtLink>.
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                Please note, our program is not designed to treat depression and anxiety conditions that have already
                developed. If your teenager is currently experiencing such difficulties, we recommend that you consult
                your GP or a mental health professional for specific assistance. However, you are still welcome to
                participate in this program as an additional source of support if you wish (see our{' '}
                <ExtLink href="/resources">Useful Resources</ExtLink> page for more information on seeking professional
                support).
              </Typography>
            </Article>
          ),
        },
      ],
    },
    {
      key: 'userType',
      title: 'Eligibility check',
      questions: [
        {
          key: 'userCategory',
          type: 'select',
          label: 'Are you a parent, guardian, or carer of at least one child aged 12 to 17?',
          options: [
            { label: 'Yes', value: 'parent' },
            { label: 'No, I am a parent of a child under 12', value: 'ineligible-under-12' },
            { label: 'No, I am a parent of a child aged over 17', value: 'ineligible-over-17' },
            { label: 'No, I am a professional checking out the program', value: 'professional' },
          ],
          required: true,
        },
      ],
    },
    {
      key: 'aboutUser',
      title: 'About you',
      questions: [
        {
          key: 'userFirstname',
          type: 'text',
          controlled: true,
          label: 'First name',
          required: true,
          size: 'small',
        },
        {
          key: 'userSurname',
          type: 'text',
          controlled: true,
          label: 'Surname',
          required: true,
          size: 'small',
        },
        {
          key: 'userAge',
          type: 'text',
          controlled: true,
          label: 'Age',
          required: true,
          size: 'small',
          validate: mustBeAge('You must be 18 years or older to take part in this program.'),
        },
        {
          key: 'userGender',
          type: 'select',
          label: 'Gender',
          options: [
            {
              label: 'Male',
              value: 'male',
            },
            {
              label: 'Female',
              value: 'female',
            },
            {
              label: 'Non-binary/gender diverse',
              value: 'non-binary',
            },
          ],
          required: true,
          size: 'small',
        },
        {
          key: 'userPostcode',
          type: 'text',
          controlled: true,
          label: 'Postcode',
          required: true,
          size: 'small',
          validate: mustBePostcode('Must be a valid postcode with 4 digits.'),
        },
        {
          key: 'userPhone',
          type: 'text',
          controlled: true,
          label: 'Preferred phone number',
          required: true,
          validate: mustBeAuNumber(
            'Must be an Australia phone number in the preferred format of +61403123456 or +61398764321. Please do not include any spaces.',
          ),
          size: 'small',
        },
        {
          key: 'userPhoneAlt',
          type: 'text',
          controlled: true,
          label: 'Alternative phone number',
          validate: mustBeAuNumber(
            'Must be an Australia phone number in the preferred format of +61403123456 or +61398764321. Please do not include any spaces.',
          ),
          size: 'small',
        },
      ],
    },
    {
      key: 'aboutFamily',
      title: 'About your family',
      questions: [
        {
          key: 'intro',
          type: 'paragraph',
          content: (
            <Typography variant="subtitle1" color="textSecondary" paragraph>
              The following few questions ask some personal information about you and your family. Answering these
              questions will help us to learn more about the parents who use the PiP program, and who benefits most.
              While we’d really appreciate you answering, if you prefer not to, you can skip these questions.
            </Typography>
          ),
        },
        {
          key: 'familyEthnicity',
          type: 'select',
          inputLabel: 'Please describe',
          label: 'Ethnicity group',
          title:
            "Which ethnicity do you most identify with? The following includes some of the most common in Australia, if yours is not captured, please use 'Other' to specify.",
          options: [
            'North African',
            'Sub-Saharan African',
            'North American',
            'Central American',
            'South American',
            'East Asian e.g. Chinese, Japanese, Korean',
            'South Asian e.g. Indian, Sri Lankan',
            'Southeast Asian e.g. Vietnamese, Malaysian',
            'Australian Aboriginal or Torres Strait Islander',
            'Australian/ New Zealand',
            'European - Eastern',
            'European - Western',
            'Maori',
            'Middle Eastern e.g. Turkish, Syrian',
            'Pacific Islander',
            { label: 'Other', value: '_input' },
            "Not sure/ I don't know",
            'Prefer not to say',
          ],
        },
        {
          key: 'familyRelationship',
          type: 'select',
          size: 'medium',
          title: 'What is your relationship status?',
          label: 'Relationship status',
          options: [
            'Married or defacto',
            'Single / no current partner',
            'Separated or divorced',
            'Widowed',
            'Prefer not to say',
          ],
        },
        {
          key: 'userEducation',
          type: 'select',
          size: 'medium',
          title: 'What is your highest level of education?',
          label: 'Education',
          inputLabel: 'Please specify',
          options: [
            'Year 7 to 11',
            'Year 12',
            'Trade/apprenticeship',
            'Other TAFE or technical course (inc. Cert I to IV)',
            'Diploma, advanced Diploma or associate degree',
            'Bachelor degree',
            'Bachelor Honours degree, Graduate Certificate or Graduate Diploma ',
            'Postgraduate degree (masters or doctorate/PhD)',
            { label: 'Other', value: '_input' },
            'Prefer not to say',
          ],
        },
        {
          key: 'familyIncome',
          type: 'select',
          title: 'What is the current total income of your household, before tax?',
          label: 'Income',
          options: [
            'Nil income',
            '$1 - $149 per week ($1 - $7,799 per year)',
            '$150 - $299 per week ($7,800 - $15,599 per year)',
            '$300 - $399 per week ($15,600 - $20,799 per year)',
            '$400 - $499 per week ($20,800 - $25,999 per year)',
            '$500 - $649 per week ($26,000 - $33,799 per year)',
            '$650 - $799 per week ($33,800 - $41,599 per year)',
            '$800 - $999 per week ($41,600 - $51,999 per year)',
            '$1000 - $1,249 per week ($52,000 - $64,999 per year)',
            '$1,250 - $1,499 per week ($65,000 - $77,999 per year)',
            '$1,500 - $1,749 per week ($78,000 - $90,999 per year)',
            '$1,750 - $1,999 per week ($91,000 - $103,999 per year)',
            '$2,000 - $2,999 per week ($104,000 - $155,999 per year)',
            '$3,000 - $3,499 per week ($156,000 - $181,999 per year)',
            '$3,500 - $3,999 per week ($182,000 - $207,999 per year)',
            '$4,000-$4,499 per week ($208,000-$233,999 per year)',
            '$4,500-$4,999 per week ($234,000-$259,999 per year)',
            '$5,000-$5,999 per week ($260,000-$311,999 per year)',
            '$6,000-$7,999 per week ($312,000-$415,999 per year)',
            '$8,000 or more per week ($416,000 or more per year)',
            'Prefer not to say',
          ],
        },
        {
          key: 'familyCovidImpact',
          type: 'checkgroup',
          title: 'Due to COVID-19, have any of these things happened in your household? Please check all that apply.',
          options: [
            'Job loss by one parent/guardian',
            'Job loss by two parents/guardians',
            'Reduced job hours for one parent/guardian',
            'Reduced job hours for two parents/guardians',
            'Difficulty paying bills or buying necessities (e.g., food)',
            'Parent/guardian having to work longer hours',
            'Applied for government (financial) assistance',
            'Received government (financial) assistance',
          ],
        },
      ],
    },
    {
      key: 'aboutTeenager',
      title: 'About your teenager',
      questions: [
        {
          key: 'intro',
          type: 'paragraph',
          content: (
            <Article>
              <Typography paragraph>
                This program is designed for parents of teenagers aged between <strong>12</strong> and{' '}
                <strong>17</strong>.
              </Typography>
              <Typography paragraph color="textSecondary">
                If you have multiple teenagers, please complete the information below in reference to the teenager you
                are most likely to apply the program strategies with.
              </Typography>
            </Article>
          ),
        },
        {
          key: 'childName',
          type: 'text',
          controlled: true,
          label: "Your teen's first name",
          helperText: 'This is required to personalise the program to you and your teen.',
          size: 'medium',
          required: true,
        },
        {
          key: 'childDob',
          type: 'text',
          controlled: true,
          label: 'Date of birth',
          size: 'medium',
          required: true,
          helperText: 'In dd/mm/yyyy format',
          validate: mustBeDateOfBirth('Must be a valid birth date in dd/mm/yyyy format before the current date.'),
        },
        {
          key: 'childGrade',
          type: 'select',
          label: 'School grade/year level',
          helperText: 'If your teenager is not attending school, please select the approximate grade they would be in.',
          required: true,
          options: [
            { label: 'Year 5', value: 5 },
            { label: 'Year 6', value: 6 },
            { label: 'Year 7', value: 7 },
            { label: 'Year 8', value: 8 },
            { label: 'Year 9', value: 9 },
            { label: 'Year 10', value: 10 },
            { label: 'Year 11', value: 11 },
            { label: 'Year 12', value: 12 },
          ],
        },
        {
          key: 'childGender',
          type: 'select',
          label: 'Gender',
          helperText: 'This is required to personalise the program to you and your teen',
          options: [
            {
              label: 'Male',
              value: 'male',
            },
            {
              label: 'Female',
              value: 'female',
            },
            {
              label: 'Non-binary/gender diverse',
              value: 'non-binary',
            },
          ],
          required: true,
          size: 'medium',
        },
        {
          key: 'childRelationship',
          type: 'select',
          label: 'Your relationship to them',
          inputLabel: 'Please specify',
          options: [
            'Mother',
            'Father',
            'Step-mother',
            'Step-father',
            'Grandmother',
            'Grandfather',
            'Guardian',
            { label: 'Other', value: '_input' },
          ],
          size: 'medium',
          required: true,
        },
        {
          key: 'childOtherParentsJoined',
          type: 'select',
          label: 'Is another parent also in this program?',
          helperText:
            'Is your child’s other parent (or co-parent) also taking part in this program? For example, biological parents, step-parents or other guardians.',
          options: ['Yes', 'No', 'Unsure'],
          required: true,
        },
      ],
    },
    {
      key: 'discover',
      title: 'How you found us',
      questions: [
        {
          key: 'discoverChannels',
          type: 'select',
          label: 'How did you find out about our program?',
          inputLabel: 'Please specify',
          options: [
            'Friends, family, or another parent',
            'Online (e.g. website, google search)',
            'Facebook',
            'Instagram',
            'In the media (e.g. radio, TV)',
            'My teenager’s school',
            'Parents Victoria or Victorian Parents Council',
            'Raising Children’s Network',
            'Partners in Parenting Podcast',
            'Spotify advertisement',
            'A parenting or family support agency',
            'Local community group',
            'My local council',
            'My GP or another health or mental health professional',
            'Prevention United',
            'I have participated in a related Monash University research study',
            { label: 'Other', value: '_input' },
          ],
        },
        {
          key: 'discoverReason',
          type: 'text',
          controlled: true,
          label: 'Reasons',
          title:
            'Can you briefly tell us your reasons for wanting to take part in the program and what you’re hoping to get from it?',
        },
        {
          key: 'discoverFutureResearch',
          type: 'radio',
          label:
            'Would you be interested in hearing about future research or parenting programs that you may be eligible for? If yes, we may get in touch via email when we are seeking parents.',
          options: ['Yes', 'No'],
        },
      ],
    },
    {
      key: 'notifications',
      title: 'Manage notifications',
      questions: [
        {
          key: 'intro',
          type: 'paragraph',
          content: (
            <Article>
              <Typography paragraph>Choose the notifications you'd like to receive from PiP.</Typography>
              <Typography paragraph color="textSecondary">
                You'll get email alerts when you have new module or survey available. If you've provided a mobile
                number, we'll also send you SMS notifications. Parents who have completed PiP in the past have found
                these helpful. If you prefer <i>not</i> to receive SMS, please uncheck the box. You can update this at
                any time during the program, under 'settings' on your dashboard.
              </Typography>
            </Article>
          ),
        },
        {
          key: 'emailNotification',
          type: 'checkbox',
          label: 'Receive Email Notifications',
          disabled: true,
        },
        {
          key: 'smsNotification',
          type: 'checkbox',
          label: 'Receive SMS Notifications',
          disabled: false,
        },
      ],
    },
    {
      key: 'terms',
      title: "What you're signing up for",
      questions: [
        {
          key: 'intro',
          type: 'paragraph',
          content: (
            <Article>
              <Typography variant="subtitle1" color="textSecondary" paragraph>
                As a parent, I understand that my participation will involve receiving the <i>Partners in Parenting</i>{' '}
                program, consisting of:
              </Typography>
              <ol>
                <li>
                  Completing a confidential online survey to receive tailored feedback about my parenting. I will also
                  receive feedback about my own and my teenager’s mental wellbeing.
                </li>
                <li>
                  Receiving a copy of the{' '}
                  <i>
                    Reducing teenagers’ risk of depression and anxiety disorders: Strategies for parents during the
                    COVID-19 pandemic
                  </i>{' '}
                  booklet immediately after completing the survey.
                </li>
                <li>
                  Access to up to 10 interactive online modules, which provide more detailed parenting tips, and each
                  take around 15-25 minutes to complete.
                </li>
                <li>Access to an optional online peer support community with other PiP parents, via Facebook.</li>
              </ol>
              <Box p={2} my={2} bgcolor="#f0f0f0">
                <ul>
                  <li>
                    I understand that I will be invited to complete some optional evaluation surveys around 3-months
                    later, to look at how my parenting approach, my teenager’s mental wellbeing, and my own mental
                    wellbeing have changed over time.
                  </li>
                  <li>
                    If I choose to join the online peer support community, I will be provided with more information
                    about my privacy and data security before I join.
                  </li>
                  <li>
                    I may also be invited to participate in an optional interview (via phone or Zoom video conferencing)
                    with the research team to give feedback on my experience of using the program.
                  </li>
                  <li>
                    I understand that I am free to use the <i>Partners in Parenting</i> program without completing the
                    optional evaluation surveys, and if I do complete them I can withdraw consent and discontinue
                    participation in the evaluation at any time without explanation. I am also able to withdraw any
                    unprocessed identifiable data previously supplied.
                  </li>
                  <li>
                    My decision whether or not to use the program or participate in the evaluation will not prejudice
                    any future relationship I or my teenager may have with Monash University.
                  </li>
                  <li>
                    I understand that all information provided by me will be kept strictly confidential within the
                    limits of the law, unless the <i>Partners in Parenting</i> team deems that I, my child, or someone
                    else is at serious risk of harm (e.g. child abuse, self-harm) that they are bound by professional
                    codes of ethics to take reasonable action to prevent, even if this means breaching confidentiality.
                  </li>
                  <li>
                    I agree that data gathered from the surveys I complete may be published in a scientific journal or
                    conference presentation, provided that I cannot be identified.
                  </li>
                  <li>
                    I understand that non-identifiable data (i.e. no one will ever be able to link my name to my
                    responses) will be made available for future research via a secure online repository, e.g. Bridges.
                  </li>
                </ul>
              </Box>
              <Typography color="textSecondary" paragraph>
                Any concerns about the scientific aspects of the program and its evaluation can be directed to Associate
                Professor Marie Yap via email, marie.yap@monash.edu
              </Typography>
              <Typography color="textSecondary" paragraph>
                Any complaints about the ethical aspects of the research may be directed to the Executive Officer,
                Monash University Human Research Ethics Committee (MUHREC), Monash University, Clayton, Vic 3800, email:
                muhrec@monash.edu, phone 03 9905 2058, fax 03 9347 6739.
              </Typography>

              <Typography color="textSecondary" paragraph>
                You can view the <ExtLink href="/settings/explanatoryStatement">Explanatory Statement</ExtLink>.
              </Typography>

              <Typography variant="h6">Please let us know that you agree to the above</Typography>

              <Typography variant="body2" color="textSecondary" paragraph>
                I have read and understood the information in the{' '}
                <ExtLink href="/settings/explanatoryStatement">Explanatory Statement</ExtLink>, and agree to participate
                in this program.
              </Typography>
            </Article>
          ),
        },
      ],
    },
  ];

  // faceook user need contact email
  if (facebookUser) {
    questions = update(questions, {
      4: {
        questions: {
          $push: [
            {
              key: 'userContactEmail',
              type: 'text',
              controlled: true,
              label: 'Contact Email',
              required: true,
              validate: mustBeEmail('Must be an valid email addresss.'),
              size: 'small',
            } as QuestionItem,
          ],
        },
      },
    });
  }

  // extra text for eligible check
  if (userCategory) {
    questions = update(questions, {
      3: {
        questions: {
          $push: [
            {
              key: 'userCategoryEligible',
              type: 'paragraph',
              content:
                userCategory == UserCategory.Other ? (
                  <Typography variant="subtitle2" gutterBottom>
                    Please note that the PiP program is designed for parents of teenagers aged 12 to 17. As your child
                    is outside of this age group, you might find that some of the content is not relevant for you. You
                    are still welcome to do the program, but we ask that you keep this in mind. Also, because we are
                    conducting a research trial of the program at the moment, we won't include your data in our results.
                    We also won't invite you to join the online peer support group. Please feel free to contact us if
                    you have any questions.
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" gutterBottom>
                    Thanks for letting us know. As a professional, you're welcome to check out the program for your own
                    interest. Please note that we will not include your data in the associated research project. We also
                    won't invite you to join the online peer support group, as this is designed for parents. Please feel
                    free to contact us if you have any questions or are interested in sharing the program with parents
                    you work with.
                  </Typography>
                ),
            } as QuestionItem,
          ],
        },
      },
    });
  }

  return questions;
};
